<template>
  <v-container class="warehouse-detail" v-if="warehouse" @click="clicked">
    <!-- <Breadcrumb :items="breadcrumb" /> -->
    <h1>{{ $t("warehouse.title") }}</h1>
    <v-row dense class="align-self-center mb-4">
      <v-col cols="12" sm="4" xl="3" class="warehouse-info-col">
        <div class="px-4 warehouse-detail-column">
          <h1>{{ warehouse.name }}</h1>
          <div class="mb-4">
            {{ warehouse.address.address1 }}
            {{
              warehouse.address.addressNumber
                ? ", " + warehouse.address.addressNumber
                : null
            }},<br />
            {{ warehouse.address.city }} ({{ warehouse.address.province }})
            {{ warehouse.address.postalcode }}<br />
            {{ warehouse.address.country.name }}
          </div>
        </div>
        <v-divider></v-divider>
        <div
          class="px-4 py-2"
          v-if="
            warehouse.metaData &&
              warehouse.metaData.warehouse_locator &&
              warehouse.metaData.warehouse_locator.DESCRIPTION
          "
          v-html="warehouse.metaData.warehouse_locator.DESCRIPTION"
        ></div>
        <v-divider></v-divider>
        <div class="px-4">
          <h4 class="font-weight-bold">Orari</h4>
          <WarehouseHours
            :serviceHours="warehouse.serviceHours"
          ></WarehouseHours>
        </div>
        <!-- <v-divider></v-divider> -->

        <v-divider class="my-6"></v-divider>

        <flyer-slider :warehouse-id="warehouse.warehouseId"></flyer-slider>

        <v-divider class="my-6"></v-divider>
        <div class="px-4">
          <h4 class="font-weight-bold">Servizi</h4>

          <div
            v-for="service in warehouse.warehouseClass"
            :key="service.name"
            class="warehouse-service d-inline-block"
          >
            <a
              v-if="warehouseClassLinks[service.warehouseClassId]"
              :href="warehouseClassLinks[service.warehouseClassId]"
            >
              <v-img
                contain
                :src="service.iconSource"
                :alt="service.description"
                :title="service.description"
                class="pdv-service-img"
              ></v-img>
            </a>
            <v-img
              v-else
              contain
              :src="service.iconSource"
              :alt="service.description"
              :title="service.description"
              class="pdv-service-img"
            ></v-img>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="8" xl="9" class="map-col">
        <GmapMap
          v-if="warehouse.warehouseId"
          ref="mapRef"
          :center="{
            lat: warehouse.address.latitude,
            lng: warehouse.address.longitude
          }"
          :zoom="15"
          :style="{ width: mapWidth }"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false
          }"
        >
          <GmapMarker
            :ref="warehouse.warehouseId"
            :position="
              google &&
                new google.maps.LatLng(
                  warehouse.address.latitude,
                  warehouse.address.longitude
                )
            "
            :clickable="true"
            icon="/img_layout/map_pointer.png"
          />
        </GmapMap>
      </v-col>
    </v-row>
    <div
      class="warehouse-footer-description pa-4"
      v-if="
        warehouse.metaData &&
          warehouse.metaData.warehouse_locator &&
          warehouse.metaData.warehouse_locator.FOOTER_DESCRIPTION
      "
      v-html="warehouse.metaData.warehouse_locator.FOOTER_DESCRIPTION"
    ></div>
    <v-btn class="primary" block elevation="0" href="/punti-vendita"
      >Torna ai punti vendita</v-btn
    >
  </v-container>
</template>
<style lang="scss">
.is-cordova {
  .warehouse-detail {
    padding: 8px;
  }
}
.warehouse-detail {
  .warehouse-footer-description {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  padding: 15px 0;
  /*.swiper-wrapper {
    justify-content: center;
  }*/
  .warehouse-info-col {
    background: var(--v-grey-lighten1);
  }
  // .maps-card {
  //   padding: 15px;
  // }
  .warehouse-list-list {
    height: 380px;
    overflow-y: auto;
  }
  .warehouse-detail-column {
    h1 {
      font-weight: 900;
      font-size: 30px;
      line-height: 2.5rem;
      padding-bottom: 12px;
    }
    font-size: 16px;
    line-height: 150%;
  }
  .pdv-service-img {
    padding: 0 8px;
    width: 80px;
  }
  .map-col {
    background: var(--v-grey-lighten1);
  }
  .vue-map-container {
    height: calc(100vh - 230px);
    position: sticky;
    top: 150px;
  }
}
</style>
<script>
import AddressService from "~/service/addressService";
import { gmapApi } from "vue2-google-maps";
import clickHandler from "~/mixins/clickHandler";
import FlyerSlider from "@/components/shopfully/FlyerSlider.vue";
import WarehouseHours from "@/components/warehouse/WarehouseHours.vue";

export default {
  name: "WarehouseDetail",
  components: { WarehouseHours, FlyerSlider },
  data() {
    return {
      warehouseList: [],
      warehouse: null,
      warehouseClassLinks: {
        "1": "/page/zona-delivery/",
        "2": "/page/prenotazione/",
        "3": "https://wa.me/393371213792?text=cosa%20possiamo%20fare%20per%20te"
      }
    };
  },
  mixins: [clickHandler],
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return "90vh";
    },
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        { to: { name: "WarehouseList" }, text: "Punti Vendita", exact: true }
      ];
      if (this.warehouse) {
        breadCrumbs.push({
          to: {
            name: "WarehouseDetail",
            params: {
              slug: this.warehouse.slug
            }
          },
          text: this.warehouse.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    async findWarehouse() {
      if (this.warehouseList.length == 0) {
        let data = await AddressService.findWarehouse({
          parent_warehouse_slug: this.slug,
          region_id: this.selectedRegionId
        });
        this.warehouseList = data.warehouses;
      }
      this.warehouse = this.warehouseList.find(
        element => element.slug == this.$route.params.slug
      );
    }
  },
  mounted() {
    this.findWarehouse();
  },
  watch: {
    $route() {
      this.findWarehouse();
    }
  },
  metaInfo() {
    if (this.warehouse) {
      const title = `Vendita all'ingrosso alimentari e bevande a ${this.warehouse.name}, ${this.warehouse.address.province}`;
      const description = `Cerchi un punto vendita all'ingrosso di alimentari, bevande, attrezzature e forniture professionali vicino a ${this.warehouse.address.province} ? Scopri
il punto vendita ZONA a ${this.warehouse.name}`;
      return {
        title: title,
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}/punti-vendita/${this.$route.params.warehouseId}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: description
          },
          // Open Graph Metas
          {
            vmid: "og:locale",
            property: "og:locale",
            content: "it_IT"
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: title
          },
          {
            vmid: "og:type",
            property: "og:type",
            content: "website"
          },
          {
            vmid: "og:url",
            property: "og:url",
            content: location.href
          },
          {
            vmid: "og:image",
            property: "og:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            vmid: "og:image:alt",
            property: "og:image:alt",
            content: `Punto vendita ${this.warehouse.name} - ZONA`
          },
          // Twitter Metas
          {
            vmid: "twitter:card",
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            vmid: "twitter:title",
            name: "twitter:title",
            content: title
          },
          {
            vmid: "twitter:image",
            name: "twitter:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            vmid: "twitter:url",
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
</script>
